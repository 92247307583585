import Vue from 'vue'
import Router from 'vue-router'
import { Role } from "./role";

Vue.use(Router)

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    return next();
  } else {
    return next({ name: 'Home' })
  }
};

const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    return next();
  } else {
    return next({ name: 'Login' })
  }
};

// + Role based authorization


// CRM >> Email
const EmailApp = () => import('@/views/crm/email/EmailApp')
const Compose = () => import('@/views/crm/email/Compose')
const Inbox = () => import('@/views/crm/email/Inbox')
const Message = () => import('@/views/crm/email/Message')


const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/crm/email',
      redirect: '/crm/email/inbox',
      name: 'Email',
      component: EmailApp,
      children: [{
          path: 'compose',
          name: 'Compose',
          component: Compose
        },
        {
          path: 'inbox',
          name: 'Inbox',
          component: Inbox
        },
        {
          path: 'starred',
          name: 'Starred',
          component: Inbox
        },
        {
          path: 'sent',
          name: 'Sent',
          component: Inbox
        },
        {
          path: 'trash',
          name: 'Trash',
          component: Inbox
        },
        {
          path: 'label/:label',
          name: 'Label',
          component: Inbox
        },
        {
          path: ':folder/:label?/:id/view',
          name: 'Message',
          component: Message
        }
      ]
    },


    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Auth/Login')
    },
    // * Now verified on the eo-main site
    // {
    //   path: '/menutepay/users/verify/:verification_token',
    //   name: 'MenutePay User Activation',
    //   component: () => import('@/views/MenutePay/users/Verify')
    // },
    {
      path: "/restaurants/sign-contract/:token",
      name: "Sign Contract",
      component: () => import('@/views/restaurants/sign-contract/SignContract.vue')
    },


    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      beforeEnter: auth,
      component: () => import(/* webpackChunkName: "container" */ '@/containers/TheContainer'),

      children: [
        {
          path: '',
          meta: { label: 'Dashboard'},
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Index')
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Index')
        },
        {
          path: '403',
          meta: { label: 'HTTP 403 Forbidden'},
          name: 'Page403',
          component: () => import('@/views/Auth/Page403')
        },

        //#region + Tasks
        {
          path: 'crm/task',
          redirect: 'crm/task',
          name: 'Task',
          component: {
            render (c) { return c('router-view') }
          },
          children: [{
              path: '',
              name: 'Task List',
              component: () => import('@/views/crm/task/TaskList'),
            }
          ]
        },
        //#endregion

        //#region + Users
        {
          path: 'users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'All Users',
              component: () => import('@/views/users/advanced/List'),
            },
            {
              path: ':id/view',
              meta: { label: 'User Details', authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'User',
              component: () => import('@/views/users/advanced/Item')
            },
            {
              path: ':id',
              meta: { label: 'Edit User', authorize: [Role.SuperAdmin] },
              name: 'Edit User',
              component: () => import('@/views/users/advanced/Edit')
            },
            {
              path: ':user_id/orders',
              meta: { label: 'User Order List', authorize: [Role.SuperAdmin, Role.Admin, Role.OrderAdmin, Role.RestAdmin] },
              name: 'UserOrderList',
              component: () => import('@/views/orders/List')
            },
            {
              path: ':user_id/reviews',
              meta: { label: 'User Reviews', authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'UserReviews',
              component: () => import('@/views/reviews/List')
            },
          ]
        },
        //#endregion

        //#region + Messages
        {
          path: 'crm/messages',
          redirect: 'crm/messages',
          name: 'Messages',
          component: {
            render (c) { return c('router-view') }
          },
          children: [{
              path: '',
              name: 'Message List',
              component: () => import('@/views/crm/messages/MessageList'),
            },
            {
              path: 'new',
              name: 'New Message',
              component: () => import('@/views/crm/messages/NewMessage'),
            }
          ]
        },
        //#endregion

        //#region + Restaurants
        {
          path: 'restaurants',
          meta: { label: 'Restaurants'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            //#region + Restaurant Types
            {
              path: 'types',
              meta: { label: 'Types', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Restaurant Types',
              component: () => import('@/views/restaurants/types/List')
            },
            {
              path: 'types/add',
              meta: { label: 'Add New Restaurant Type', authorize: [Role.SuperAdmin] },
              name: 'Add New Type',
              component: () => import('@/views/restaurants/types/Item')
            },
            {
              path: 'types/:id',
              meta: { label: 'Edit Restaurant Type', authorize: [Role.SuperAdmin] },
              name: 'Edit Type',
              component: () => import('@/views/restaurants/types/Item')
            },
            //#endregion

            //#region + Restaurant
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Restaurants',
              component: () => import('@/views/restaurants/List')
            },
            {
              path: ':id/view',
              meta: { label: 'Restaurant', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'View Restaurant',
              component: () => import('@/views/restaurants/View')
            },
            {
              path: 'add',
              redirect: 'wizard/add',
              name: 'Add New Restaurant',
              // meta: { label: 'Add New Restaurant', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              // component: () => import('@/views/restaurants/Item')
            },
            {
              path: ':id',
              meta: { label: 'Edit Restaurant', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Edit Restaurant',
              component: () => import('@/views/restaurants/Item')
            },
            //#endregion

            //#region + Restaurant Wizard
            {
              path: 'wizard/add',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin], label: 'Restaurant Creation Wizard'},
              name: 'RestaurantCreationWizard',
              component: () => import('@/views/restaurants/wizard/Container')
            },
            //#endregion

            //#region + Restaurant Linked Types
            {
              path: ':restaurant_id/types',
              meta: { label: 'Linked Types', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'RestaurantLinkedTypes',
              component: () => import('@/views/restaurants/linked-types/List')
            },
            //#endregion

            //#region + ZipCodes Linked Restaurant
            {
              path: ':restaurant_id/zipcodes',
              meta: { label: 'Linked ZIP Codes', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'RestaurantLinkedZIPCodes',
              component: () => import('@/views/restaurants/linked-zipcodes/List')
            },
            {
              path: ':restaurant_id/zipcodes/add',
              meta: { label: 'Add New Linked ZIP Code', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'AddNewLinkedZIPCode',
              component: () => import('@/views/restaurants/linked-zipcodes/Item')
            },
            //#endregion

            //#region + Restaurant Linked Categories
            {
              path: ':restaurant_id/categories',
              meta: { label: 'Linked Categories', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'RestaurantLinkedCategories',
              component: () => import('@/views/restaurants/linked-categories/List')
            },
            {
              path: ':restaurant_id/categories/add',
              meta: { label: 'Add New Linked Category', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'AddNewLinkedCategory',
              component: () => import('@/views/restaurants/linked-categories/Item')
            },
            {
              path: ':restaurant_id/categories/:id',
              meta: { label: 'Edit Linked Category', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'EditLinkedCategory',
              component: () => import('@/views/restaurants/linked-categories/Item')
            },
            //#endregion

            //#region + Restaurant Linked Menus
            {
              path: ':restaurant_id/menu',
              meta: { label: 'Linked Menus', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'RestaurantLinkedMenus',
              component: () => import('@/views/restaurants/linked-menus/List')
            },
            {
              path: ':restaurant_id/menu/add',
              meta: { label: 'Add New Linked Menu', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'AddNewLinkedMenu',
              component: () => import('@/views/restaurants/linked-menus/Item')
            },
            {
              path: ':restaurant_id/menu/:id',
              meta: { label: 'Edit Linked Menu', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'EditLinkedMenu',
              component: () => import('@/views/restaurants/linked-menus/Item')
            },
            //#endregion

            //#region + Restaurant Permission List
            {
              path: ':restaurant_id/permissions',
              meta: { label: 'Restaurant Permission List' },
              name: 'RestaurantPermissionList',
              component: () => import('@/views/restaurants/permissions/List')
            },
            //#endregion

            //#region + Restaurant Reviews
            {
              path: ':restaurant_id/reviews',
              meta: { label: 'Restaurant Reviews', authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'RestaurantReviews',
              component: () => import('@/views/reviews/List')
            },
            //#endregion

            //#region + Restaurant Order List
            {
              path: ':restaurant_id/orders',
              meta: { label: 'Restaurant Order List', authorize: [Role.SuperAdmin, Role.Admin, Role.OrderAdmin, Role.RestAdmin] },
              name: 'RestaurantOrderList',
              component: () => import('@/views/orders/List')
            },
            //#endregion

            //#region + Restaurant Menu Sjabloons
            {
              path: ':restaurant_id/menu-sjabloons',
              meta: { label: 'Menu Sjabloons', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Restaurant Menu Sjabloons',
              component: () => import('@/views/menu-sjabloons/List')
            },
            {
              path: ':restaurant_id?/menu-sjabloons/add',
              meta: { label: 'Add New Menu Sjabloon', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Add New Menu Sjabloon',
              component: () => import('@/views/menu-sjabloons/Item')
            },
            {
              path: ':restaurant_id/menu-sjabloons/:id',
              meta: { label: 'Edit Menu Sjabloon', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Edit Menu Sjabloon',
              component: () => import('@/views/menu-sjabloons/Item')
            },
            //#endregion

            //#region + Restaurant Payment List
            {
              path: ':restaurant_id/payments',
              meta: { label: 'Restaurant Payment List', authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'RestaurantPaymentList',
              component: () => import('@/views/payments/List')
            },
            //#endregion

            //#region + Restaurant Task List
            {
              path: ':restaurant_id/tasks',
              meta: { label: 'Restaurant Task List' },
              name: 'RestaurantTaskList',
              component: () => import('@/views/crm/task/TaskList')
            },
            //#endregion

            //#region + Restaurant Opening Times
            {
              path: ':restaurant_id/opening-times',
              meta: { label: 'Custom Opening Times' },
              name: 'RestaurantOpeningTimes',
              component: () => import('@/views/restaurants/opening-times/Item')
            },
            //#endregion
          ]
        },
        //#endregion

        //#region + Reviews
        {
          path: 'reviews',
          meta: { label: '' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'Reviews',
              component: () => import('@/views/reviews/List')
            },
          ]
        },
        //#endregion

        //#region + Delivery Zones
        {
          path: 'delivery-zones',
          meta: { label: '' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Delivery Zones',
              component: () => import('@/views/delivery-zones/List')
            },
          ]
        },
        //#endregion

        //#region + Maps
        {
          path: 'maps',
          meta: { label: '' },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'restaurants',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'All Restaurants On Map',
              component: () => import('@/views/maps/restaurants/List')
            },
          ]
        },
        //#endregion

        //#region + Devices
        {
          path: 'devices',
          meta: { label: ''},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Devices',
              component: () => import('@/views/devices/List')
            },
            {
              path: 'add',
              meta: { label: 'Add New Device', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Add New Device',
              component: () => import('@/views/devices/Item')
            },
            {
              path: ':id',
              meta: { label: 'Edit Device', authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin] },
              name: 'Edit Device',
              component: () => import('@/views/devices/Item')
            },
          ]
        },
        //#endregion

        //#region + Menu Groups
        {
          path: 'menu-groups',
          meta: { label: ''},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Menu Groups',
              component: () => import('@/views/menu-groups/List')
            },
            {
              path: 'add',
              meta: { label: 'Add New Menu Group', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Add New Menu Group',
              component: () => import('@/views/menu-groups/Item')
            },
            {
              path: ':id',
              meta: { label: 'Edit Menu Group', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Edit Menu Group',
              component: () => import('@/views/menu-groups/Item')
            },


            {
              path: ':mnuExtGrpId/childs',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Menu Group Childs',
              component: () => import('@/views/menu-groups/childs/List')
            },
            {
              path: ':mnuExtGrpId/childs/add',
              meta: { label: 'Add New Menu Group Child', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Add New Menu Group Child',
              component: () => import('@/views/menu-groups/childs/Item')
            },
            {
              path: ':mnuExtGrpId/childs/:id',
              meta: { label: 'Edit Menu Group Child', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Edit Menu Group Child',
              component: () => import('@/views/menu-groups/childs/Item')
            },
          ]
        },
        //#endregion

        //#region + Menu Sjabloons
        {
          path: 'menu-sjabloons',
          meta: { label: ''},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Menu Sjabloons',
              component: () => import('@/views/menu-sjabloons/List')
            }
          ]
        },
        //#endregion

        //#region + Orders
        {
          path: 'orders',
          meta: { label: 'Orders'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.OrderAdmin, Role.RestAdmin] },
              name: 'Order List',
              component: () => import('@/views/orders/List')
            },
            {
              path: ':id',
              meta: { label: 'Order Details', authorize: [Role.SuperAdmin, Role.Admin, Role.OrderAdmin] },
              name: 'Order Details',
              component: () => import('@/views/orders/Item')
            },
          ]
        },
        //#endregion

        //#region + Invoices
        {
          path: 'invoices',
          meta: { label: 'Invoices'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin] },
              name: 'Invoice List',
              component: () => import('@/views/invoices/List')
            },
            {
              path: 'txn/:txn_id',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin], label: 'Invoice List by Bank Transaction' },
              name: 'InvoiceListByTxn',
              component: () => import('@/views/invoices/List')
            }
          ]
        },
        //#endregion

        //#region + Payments
        {
          path: 'payments',
          meta: { label: 'Payments'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'Payment List',
              component: () => import('@/views/payments/List')
            }
          ]
        },
        //#endregion

        //#region + Bank Transactions
        {
          path: 'bank-transactions',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin] },
              name: 'Bank Transactions',
              component: () => import('@/views/bank-transactions/List')
            }
          ]
        },
        //#endregion

        //#region + Reports
        {
          path: 'reports',
          meta: { label: 'Reports'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'revenue',
              meta: { authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'Revenue',
              component: () => import('@/views/reports/Revenue')
            }
          ]
        },
        //#endregion

        //#region + Activities
        {
          path: 'activities',
          meta: { label: 'Activities'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { authorize: [Role.SuperAdmin] },
              name: 'Activities',
              component: () => import('@/views/activities/List')
            }
          ]
        },
        //#endregion

        //#region + Images
        {
          path: 'images',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Images', authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin] },
              name: 'Images',
              component: () => import('@/views/images/Main')
            },
          ]
        },
        //#endregion

        //#region + MenutePay
        {
          path: 'menutepay',
          // meta: { label: 'MenutePay'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [

            //#region + Company
            {
              path: 'companies',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'MenutePay Companies', authorize: [Role.SuperAdmin, Role.Admin] },
                  name: 'Companies',
                  component: () => import('@/views/MenutePay/companies/List')
                },
                // {
                //   path: ':id/view',
                //   meta: { label: 'Company', authorize: [Role.SuperAdmin, Role.Admin] },
                //   name: 'View Company',
                //   component: () => import('@/views/MenutePay/companies/View')
                // },
                {
                  path: 'add',
                  meta: { label: 'Add New Company', authorize: [Role.SuperAdmin, Role.Admin] },
                  name: 'Add New Company',
                  component: () => import('@/views/MenutePay/companies/Item')
                },
                {
                  path: ':id',
                  meta: { label: 'Edit Company', authorize: [Role.SuperAdmin, Role.Admin] },
                  name: 'Edit Company',
                  component: () => import('@/views/MenutePay/companies/Item')
                },
              ]
            },
            //#endregion

            //#region + MenutePay Invoices
            {
              path: 'invoices',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin] },
                  name: 'MenutePay Invoices',
                  component: () => import('@/views/MenutePay/invoices/List')
                },
              ]
            },
            //#endregion

            //#region + MenutePay Payments
            {
              path: 'payments',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { authorize: [Role.SuperAdmin, Role.Admin] },
                  name: 'MenutePay Payments',
                  component: () => import('@/views/MenutePay/payments/List')
                },
              ]
            },
            //#endregion

            //#region + MenutePay Users
            {
              path: 'users',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { authorize: [Role.SuperAdmin, Role.Admin] },
                  name: 'MenutePay Users',
                  component: () => import('@/views/MenutePay/users/List')
                },
              ]
            },
            //#endregion
          ]
        },
        //#endregion

        //#region + Courier
        {
          path: 'courier',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            // Hubs
            {
              path: 'hubs',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'Hubs', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'Hubs',
                  component: () => import('@/views/Courier/Hubs/List')
                },
                {
                  path: 'add',
                  meta: { label: 'Add New Hub', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'Add New Hub',
                  component: () => import('@/views/Courier/Hubs/Item')
                },
                {
                  path: ':id',
                  meta: { label: 'Edit Hub', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'Edit Hub',
                  component: () => import('@/views/Courier/Hubs/Item')
                },
              ]
            },
            // Couriers
            {
              path: 'couriers',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'Couriers', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'Couriers',
                  component: () => import('@/views/Courier/Couriers/List')
                },
                {
                  path: 'add',
                  meta: { label: 'Add New Courier', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'Add New Courier',
                  component: () => import('@/views/Courier/Couriers/Item')
                },
                {
                  path: ':id',
                  meta: { label: 'Edit Courier', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'Edit Courier',
                  component: () => import('@/views/Courier/Couriers/Item')
                },
              ]
            },
            // DeliveryService
            {
              path: 'delivery-service',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  meta: { label: 'Delivery Service', authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin] },
                  name: 'DeliveryService',
                  component: () => import('@/views/Courier/DeliveryService/List')
                }
              ]
            },
          ]
        },
        //#endregion

        //#region + Holidays
        {
          path: 'holidays',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Holidays', authorize: [Role.SuperAdmin, Role.Admin] },
              name: 'Holidays',
              component: () => import('@/views/holidays/List')
            },
          ]
        },
        //#endregion

        //#region + Voucher
        {
          path: 'vouchers',
          redirect: 'vouchers',
          name: 'Vouchers',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Voucher List', authorize: [Role.SuperAdmin] },
              name: 'Voucher List',
              component: () => import('@/views/vouchers/List')
            },
            {
              path: 'add',
              meta: { label: 'Add New Voucher', authorize: [Role.SuperAdmin] },
              name: 'Add New Voucher',
              component: () => import('@/views/vouchers/Item')
            },
            {
              path: ':id',
              meta: { label: 'Edit Voucher', authorize: [Role.SuperAdmin] },
              name: 'Edit Voucher',
              component: () => import('@/views/vouchers/Item')
            },
          ]
        },
        //#endregion

        {
          path: '/:catchAll(.*)',
          name: 'Not Found',
          component: () => import(/* webpackChunkName: "404" */ '@/views/Auth/Page404')
        },
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const auth = await import('@/store/auth');
  const authUser = auth.authService.currentUser;

  const { authorize } = to.meta;

  if (authorize) {
    if (!authUser) {
      // not logged in so redirect to login page with the return url
      return next({ name: 'Login', query: { returnUrl: to.path } });
    }

    // const authUserRoles = authUser.roles.map(role => role.name);
    const authUserRoles = authUser.roles.length == 0 ? ['Admin'] : authUser.roles.map(role => role.name);

    // check if route is restricted by role
    if (authorize.length && !authorize.some((role) => authUserRoles.includes(role))) {
      // role not authorised so redirect to home page
      return next({ name: "Page403" });
    }
  }

  next();
});

router.afterEach((to, from) => {
  // Set page title
  let title = to.meta.label || to.name;

  if (to.params.label) {
    let label = router.app.$globalVar.emailLabels.find(
      (el) => el.value === to.params.label
    );
    title = `"${label?.label}"`;
  }
  document.title = `${title} - ${process.env.VUE_APP_APP_TITLE}`;
});

export default router
